@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

.logo-font {
	font-family: 'Pacifico', cursive;
}

@media (min-width: 576px) {
	.login-card {
		position: relative;
		top: 50%;
		width: 560px;
		margin-left: auto;
		margin-right: auto;
		transform: translateY(-50%);
	}

	ion-header {
		display: none;
	}
}
